<template>
  <header>
    <div class="contents_header">
      <div class="line_btn_mo">
        <atoms-c-image
          v-log-a2s-click
          data-a2s-obj-name="GNB_BI"
          class="img_logo"
          file-name="logo"
          :density="3"
          width="200px"
          @click="moveMenuPath('/')"
        />

        <atoms-c-icon
          class="btn_menu_mo mo_only"
          :icon-name="isOpenMobileMenu ? 'gnb_close' : 'gnb_burger'"
          @click="toggleMobileMenu"
        ></atoms-c-icon>
      </div>
      <ul
        v-show="isOpenMobileMenu"
        class="section_menu"
        :class="{ active: isOpenMobileMenu }"
      >
        <li
          v-for="(menuItem, idx) in menus"
          :key="idx"
          class="parent_menu"
          :class="{ active: activeMenuId === menuItem.id }"
          @click="onClickMenuItem(menuItem)"
          @mouseenter="setHoverMenu(menuItem.id)"
          @mouseleave="setHoverMenu(null)"
        >
          <div
            v-log-a2s-click
            class="item_menu"
            :data-a2s-obj-name="menuItem.a2sObj"
          >
            <atoms-c-icon :icon-name="menuItem.icon"></atoms-c-icon>
            <span class="text_menu_name">{{ menuItem.name }}</span>
            <atoms-c-icon
              v-if="menuItem.child.length"
              class="mo_only btn_child_menu"
              icon-name="chevron_right"
              :class="{ is_open: openMenuId === menuItem.id }"
            />
          </div>
          <div v-show="openMenuId === menuItem.id" class="wrap_child_menu">
            <ul class="child_menu">
              <li
                v-for="(
                  { name: childName, path, a2sObj }, childIdx
                ) in menuItem.child"
                :key="childIdx"
                v-log-a2s-click
                :class="{
                  active: route.path.startsWith(path)
                }"
                :data-a2s-obj-name="a2sObj"
                @click.stop="moveMenuPath(path)"
              >
                <div>{{ childName }}</div>
              </li>
            </ul>
          </div>
        </li>
        <li
          v-log-a2s-click
          data-a2s-obj-name="GNB_Signup"
          class="btn_join_mo mo_only"
          @click="onClickJoin"
        >
          회원가입
        </li>
      </ul>
      <atoms-default-button
        v-log-a2s-click
        data-a2s-obj-name="GNB_Signup"
        class="btn_join pc_only"
        text="회원가입"
        @click="onClickJoin"
      ></atoms-default-button>
    </div>
  </header>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { useGlobalStore } from '~/stores/global'
const { $customDevice } = useNuxtApp()
interface menu {
  id: string
  name: string
  icon: string
  path?: string
  a2sObj: string
  child: {
    name: string
    path: string
    isBoard: boolean
    a2sObj: string
  }[]
}

const menus: menu[] = [
  {
    id: 'introduce',
    name: '소개',
    icon: 'gnb_intro',
    path: '/introduce',
    a2sObj: 'GNB_Intro',
    child: []
  },
  {
    id: 'speaker',
    name: '소식',
    icon: 'gnb_speaker',
    a2sObj: 'GNB_Updates',
    child: [
      {
        name: '공지사항',
        path: '/notice',
        isBoard: true,
        a2sObj: 'GNB_Notice'
      },
      { name: '뉴스', path: '/news', isBoard: true, a2sObj: 'GNB_News' }
    ]
  },
  {
    id: 'support',
    name: '고객 지원',
    icon: 'gnb_speech_bubble',
    a2sObj: 'GNB_CS',
    child: getSupportChildMenu()
  }
]

/**
 * 기기에 따른 고객지원 하위 메뉴 반환
 */
function getSupportChildMenu() {
  const childMenu = []
  const isMobile = $customDevice.isMobileOrTablet
  if (!isMobile) {
    childMenu.push({
      name: '다운로드',
      path: '/download',
      isBoard: false,
      a2sObj: 'GNB_Download'
    })
  }
  childMenu.push({
    name: 'FAQ',
    path: '/faq?headlineId=sticky',
    isBoard: true,
    a2sObj: 'GNB_FAQ'
  })
  return childMenu
}
const router = useRouter()
const route = useRoute()
const { isOpenMobileMenu } = storeToRefs(useGlobalStore())

const activeMenuId = computed(() => {
  for (const idx in menus) {
    const { id, path, child } = menus[idx]
    if (path && route.path.startsWith(path)) {
      return id
    }
    const activeChildIdx = child.find((c) => route.path.startsWith(c.path))
    if (activeChildIdx) {
      return id
    }
  }
  return null
})
const openMenuId: Ref<string | null> = ref(null)
function setHoverMenu(id: string | null) {
  if (!isOpenMobileMenu.value) {
    openMenuId.value = id
  }
}
function onClickMenuItem(menuItem: menu) {
  let path = menuItem.path
  if (isOpenMobileMenu.value) {
    if (openMenuId.value === menuItem.id) {
      openMenuId.value = null
    } else {
      openMenuId.value = menuItem.id
    }
  } else if (!path) {
    path = menuItem.child?.[0]?.path
  }

  if (path) {
    moveMenuPath(path)
  }
}
function moveMenuPath(path: string) {
  router.push(path)
  setHoverMenu(null)
  isOpenMobileMenu.value = false
}
function toggleMobileMenu() {
  isOpenMobileMenu.value = !isOpenMobileMenu.value
}

function onClickJoin() {
  const routeData = router.resolve({ name: 'join' })
  window.open(routeData.href, '_blank')
}
onMounted(() => {
  // PC 사이즈가 되었을 때 모바일 메뉴가 열려있으면 닫기
  const mql = window.matchMedia('screen and (min-width: 1000px)')
  mql.addEventListener('change', (event) => {
    if (event.matches && isOpenMobileMenu.value) {
      isOpenMobileMenu.value = false
    }
  })
})
onBeforeUnmount(() => {
  isOpenMobileMenu.value = false
})
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: fixed;
  top: 20px;
  margin-top: $bannerHeight;
  z-index: 12;
  .contents_header {
    display: flex;
    max-width: 1280px;
    box-sizing: border-box;
    width: calc(100% - 64px);
    padding: 0 24px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    border-radius: 500px;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(0, 5, 113, 0.06);
    .section_menu {
      display: block !important;
    }
    .img_logo {
      cursor: pointer;
      margin-top: -2px;
    }
    .parent_menu {
      display: inline-block;
      position: relative;
      color: #393c41;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.72px;
      padding: 24px 20px;
      cursor: pointer;
      &:hover,
      &.active {
        color: #000000;
      }
      i,
      .text_menu_name {
        vertical-align: middle;
      }
      .text_menu_name {
        padding-left: 8px;
      }
      // 메뉴바와 하위 메뉴 사이의 투명한 영역을 위함
      .wrap_child_menu {
        position: absolute;
        background: transparent;
        top: 78px;
        left: 50%;
        margin-left: -60px;
        padding-top: 10px;
        .child_menu {
          border-radius: 8px;
          background: #ffffff;
          box-shadow: 0 4px 20px 0 rgba(0, 5, 113, 0.06);
          padding: 16px 0;
          color: $black0;
          li {
            width: 160px;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.4;
            letter-spacing: -0.64px;

            &:hover,
            &.active {
              background: $bg-l-purple;
              color: $blue1;
            }
          }
        }
      }
    }
    .btn_join {
      margin-top: -2px;
      margin-left: 106px;
      padding: 12px 16px;
      border-radius: 50px;
      box-shadow: 0 4px 0 0 #3743c4;
      :deep(.txt_btn) {
        line-height: 1;
      }
    }
  }
}

@media (max-width: 999px) {
  header {
    overflow-y: auto;
    width: 100%;
    top: 0;
    .contents_header {
      padding: 16px;
      border-radius: 0;
      flex-direction: column;
      width: 100%;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      .section_menu {
        display: none !important;
        width: 100%;
        height: calc(100vh - 64px - $bannerHeight);
        padding-top: 42px;
        flex-direction: column;
        overflow-y: auto;
        box-sizing: border-box;
        &.active {
          display: flex !important;
        }
        .parent_menu {
          display: block;
          padding: 0;
          border-bottom: 1px dashed #e9eef4;
          .item_menu {
            display: flex;
            align-items: center;
            padding: 20px 8px;
            font-size: 24px;
            letter-spacing: -0.96px;
          }
          i {
            width: 32px;
            height: 32px;
          }
          .btn_child_menu {
            width: 24px;
            height: 24px;
            background-color: #d6e5fb;
            border-radius: 50%;
            margin-left: auto;
            &.is_open {
              transform: rotate(180deg);
            }
          }
          .wrap_child_menu {
            position: static;
            background: #ffffff;
            margin-left: 0;
            padding-top: 0;

            .child_menu {
              border-radius: 0;
              box-shadow: none;
              padding: 0;

              li {
                width: auto;
                padding: 16px 24px;
                justify-content: left;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: -0.8px;
                background-color: $bg-l-blue2;
              }
            }
          }
        }
      }
      .line_btn_mo {
        width: 100%;
        display: flex;
        .btn_menu_mo {
          cursor: pointer;
          display: block;
          margin-left: auto;
        }
      }
      .img_logo {
        margin-top: 0;
        width: 135px;
        height: 27px;
      }
    }
    .btn_join_mo {
      color: $gray0;
      margin-top: auto;
      padding: 50px 0 150px; // ???
      align-self: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.72px;
      text-decoration-line: underline;
    }
  }
}
</style>
